import React, {useContext} from 'react';

// Hooks and methods
import {SideNavContext} from './side-nav-context';
import {useIsMobileView} from '@compt/utils/mobile-helpers';

// Types
import comptColors from '@compt/styles/compt-colors';
import {SECTION_TYPE, SectionType} from '../compt-layout/compt-layout.controller';
import {SideNavItemType} from '@compt/common/compt-side-nav/compt-side-nav.types';

// Components
import {ComptCollapsibleSideNavSection} from './compt-collapsible-side-nav-section';
import {ComptHelpScoutLink} from '@compt/common/compt-helpscout-link/compt-helpscout-link';
import {ComptSecondarySideNav} from '@compt/common/compt-side-nav/compt-secondary-side-nav';
import {ComptSideNavSection} from './compt-side-nav-section';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {MobileSideNav} from './compt-mobile-side-nav';
import {Tooltip} from 'react-tooltip';
import {Transition} from '@headlessui/react';

export type ComptSideNavProps = {
  navSections: SectionType[];
  onlyIcon?: boolean;
};

const AdminNavSections = ({navSections}: ComptSideNavProps) => {
  const generalAdminSection = navSections.find(
    (section) => section.type === SECTION_TYPE.GENERAL_ADMIN,
  );
  const collapsibleSections = navSections.filter((section) => section?.isCollapsible);

  if (collapsibleSections.length === 0 || !generalAdminSection) {
    return null;
  }

  const generalAdminItems = generalAdminSection.items.map((item) => item.sideNavItem);
  return (
    <>
      <hr className="my-[17px]" />
      <div className="pb-1">
        <ComptSideNavSection sectionItems={generalAdminItems} />
      </div>
      <div className={'grid gap-y-1'}>
        {collapsibleSections?.map((section, i) => (
          <ComptCollapsibleSideNavSection
            key={`collapsible-${i}`}
            id={`section-id-${i}`}
            sectionTitle={section.title}
            sectionItems={[]}
            sectionIcon={section.sectionIcon}
            sectionType={section.type}
          />
        ))}
      </div>
    </>
  );
};

const EmployeeNavSection = ({navSections}: ComptSideNavProps) => {
  const employeeNavSection = navSections?.find((section) => section.type === SECTION_TYPE.EMPLOYEE);

  if (!employeeNavSection) {
    return null;
  }

  const employeeSideNavItems = employeeNavSection.items.map((item) => item.sideNavItem);
  return (
    <div>
      <ComptSideNavSection sectionItems={employeeSideNavItems} />
    </div>
  );
};

const MiscellaneousNavSection = ({navSections}: ComptSideNavProps) => {
  const miscellaneousNavSection = navSections?.find(
    (section) => section.type === SECTION_TYPE.MISC,
  );

  if (!miscellaneousNavSection) return null;

  const miscellaneousItems = miscellaneousNavSection.items.map((item) => item.sideNavItem);
  return (
    <div className="pt-1">
      <ComptSideNavSection sectionItems={miscellaneousItems} />
    </div>
  );
};

export const ComptSideNav = ({navSections}: ComptSideNavProps) => {
  const {
    isAdminView,
    sidebarOpen,
    setSidebarOpen,
    stipendAdminOpen,
    expenseAdminOpen,
    internalAdminOpen,
    learningAdminOpen,
    setStipendAdminOpen,
    setExpenseAdminOpen,
    setInternalAdminOpen,
    setLearningAdminOpen,
  } = useContext(SideNavContext);
  const isMobileView = useIsMobileView();

  const SectionSecondaryNav = ({navSections}: ComptSideNavProps) => {
    const getSectionNav = (items: SideNavItemType[], type: SECTION_TYPE) => (
      <ComptSecondarySideNav sectionItems={items} sectionType={type} />
    );

    const stipendNavItems = navSections
      ?.find((section) => section.type === SECTION_TYPE.STIPEND_ADMIN)
      ?.items.map((item) => item.sideNavItem);

    const expenseNavItems = navSections
      ?.find((section) => section.type === SECTION_TYPE.EXPENSE_ADMIN)
      ?.items.map((item) => item.sideNavItem);

    const internalNavItems = navSections
      ?.find((section) => section.type === SECTION_TYPE.INTERNAL_ADMIN)
      ?.items.map((item) => item.sideNavItem);

    const learningNavItems = navSections
      ?.find((section) => section.type === SECTION_TYPE.LEARNING_ADMIN)
      ?.items.map((item) => item.sideNavItem);

    if (stipendAdminOpen && stipendNavItems) {
      return getSectionNav(stipendNavItems, SECTION_TYPE.STIPEND_ADMIN);
    }

    if (expenseAdminOpen && expenseNavItems) {
      return getSectionNav(expenseNavItems, SECTION_TYPE.EXPENSE_ADMIN);
    }

    if (internalAdminOpen && internalNavItems) {
      return getSectionNav(internalNavItems, SECTION_TYPE.INTERNAL_ADMIN);
    }

    if (learningAdminOpen && learningNavItems) {
      return getSectionNav(learningNavItems, SECTION_TYPE.LEARNING_ADMIN);
    }

    return null;
  };

  const closeSecondaryNav = () => {
    if (stipendAdminOpen) {
      setStipendAdminOpen((prevState) => !prevState);
    }
    if (expenseAdminOpen) {
      setExpenseAdminOpen((prevState) => !prevState);
    }
    if (internalAdminOpen) {
      setInternalAdminOpen((prevState) => !prevState);
    }
    if (learningAdminOpen) {
      setLearningAdminOpen((prevState) => !prevState);
    }
  };

  if (isMobileView) {
    return <MobileSideNav navSections={navSections} closeSecondaryNav={closeSecondaryNav} />;
  }

  return (
    <>
      <div
        className={`${sidebarOpen ? 'w-[288px] pt-8' : 'w-[80px] pt-6'} ${
          !sidebarOpen && isAdminView && 'w-[90px]'
        } border-r transition-all duration-300 ease-in-out transform flex h-full bg-white`}
        // Add in-line styling to show within admin experience
        style={{
          borderRight: `1px solid ${comptColors.gray[200]}`,
        }}
      >
        <aside className="w-full px-4 h-full overflow-x-hidden flex flex-col justify-between">
          <div>
            <div onMouseEnter={closeSecondaryNav}>
              <EmployeeNavSection navSections={navSections} />
            </div>
            <AdminNavSections navSections={navSections} />
            <MiscellaneousNavSection navSections={navSections} />
            <Transition
              show={sidebarOpen}
              enter="transition-opacity duration-300 delay-200"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            ></Transition>
          </div>
          {!sidebarOpen && (
            <Tooltip
              anchorSelect="#collapse-menu-button"
              place="right"
              opacity={100}
              style={{borderRadius: '6px'}}
            >
              Expand menu
            </Tooltip>
          )}
          <div onMouseEnter={closeSecondaryNav}>
            <ComptHelpScoutLink sidebarOpen={sidebarOpen} />
            <hr className="my-6" />
            <button
              id="collapse-menu-button"
              type="button"
              className="flex items-center"
              onClick={() => setSidebarOpen((previousState) => !previousState)}
              // In-line style necessary to override admin page stylesheet
              style={{borderRadius: '6px', padding: '8px 12px 32px'}}
            >
              <ComptSvgIcon
                iconName={sidebarOpen ? 'arrow-circle-collapse-icon' : 'arrow-circle-expand-icon'}
              />
              <Transition
                show={sidebarOpen}
                enter="transition-opacity duration-300 delay-200"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <p className="body2 text-color-body1 pl-3 flex justify-start whitespace-nowrap">
                  Collapse menu
                </p>
              </Transition>
            </button>
          </div>
        </aside>
      </div>
      <SectionSecondaryNav navSections={navSections} />
    </>
  );
};
